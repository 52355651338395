function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
// Utils
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { PoodliQueryParams } from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "webclient/src/utils/paths";
// webclient nav
export var NAV_DRAWER_OPEN_WIDTH = 214;
export var NAV_DRAWER_CLOSED_WIDTH = 74;
export var lineClampStyles = function(lineCount) {
    return {
        display: "-webkit-box",
        WebkitLineClamp: lineCount.toString(),
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    };
};
export var areRealTimeAlertsEnabled = function(userDocMain) {
    var _userDocMain_poodliStatuses;
    return (userDocMain === null || userDocMain === void 0 ? void 0 : (_userDocMain_poodliStatuses = userDocMain.poodliStatuses) === null || _userDocMain_poodliStatuses === void 0 ? void 0 : _userDocMain_poodliStatuses.realTimeAlertsEnabled) === undefined ? true : userDocMain.poodliStatuses.realTimeAlertsEnabled;
};
export function isWhiteLabel() {
    return getSiteId() !== "yoodli";
}
/**
 * Returns only the unique instances of an array of objects based on a predicate
 * @param {*} arr
 * @param {*} predicate
 * @returns array of unique objects
 * @example uniqBy([{id: 1, name: 'a'}, {id: 2, name: 'b'}, {id: 1, name: 'c'}], 'id')
 *   return [{id: 1, name: 'a'}, {id: 2, name: 'b'}]
 */ // Fixme: add return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var uniqBy = function(arr, predicate) {
    var cb = typeof predicate === "function" ? predicate : function(o) {
        return o[predicate];
    };
    return _to_consumable_array(arr.reduce(function(map, item) {
        var key = item === null || item === undefined ? item : cb(item);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        map.has(key) || map.set(key, item);
        return map;
    }, new Map()).values());
};
/**
 * This function removes all non-digit characters and then parses the remaining string
 *
 * This exists because we sometimes can't use parseInt directly because the string begins with letters, e.g. parseInt("Start: 100") === NaN
 * @param str - The string to convert to an integer
 * @returns The integer value of the string
 */ export var getIntFromString = function(str) {
    return parseInt(str.replace(/\D/g, ""), 10);
};
/**
 * Returns sorted array of objects by key
 * @param {*} arr
 * @param {*} key
 * @returns sorted array of objects
 * @example
 * const arr = [{a: 1}, {a: 2}, {a: 0}]
 * sortByKey(arr, 'a') // [{a: 0}, {a: 1}, {a: 2}]
 */ export var sortByKey = function(arr, key) {
    return arr.sort(function(a, b) {
        if (!a[key] && b[key]) return -1;
        if (!b[key] && a[key]) return 1;
        if (a[key] === b[key]) return 0;
        return a[key] > b[key] ? 1 : -1;
    });
};
export var convertHexToRGBA = function(hexCode) {
    var opacity = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    var hex = hexCode.replace("#", "");
    if (hex.length === 3) {
        hex = "".concat(hex[0]).concat(hex[0]).concat(hex[1]).concat(hex[1]).concat(hex[2]).concat(hex[2]);
    }
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    /* Backward compatibility for whole number based opacity values. */ if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }
    return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(opacity, ")");
};
export var toTitleCase = function(str) {
    var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
    var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
    var wordSeparators = /([ :–—-])/;
    return str.split(wordSeparators).map(function(current, index, array) {
        if (/* Check for small words */ current.search(smallWords) > -1 && /* Skip first and last word */ index !== 0 && index !== array.length - 1 && /* Ignore title end and subtitle start */ array[index - 3] !== ":" && array[index + 1] !== ":" && /* Ignore small words that start a hyphenated phrase */ (array[index + 1] !== "-" || array[index - 1] === "-" && array[index + 1] === "-")) {
            return current.toLowerCase();
        }
        /* Ignore intentional capitalization */ if (current.substring(1).search(/[A-Z]|\../) > -1) {
            return current;
        }
        /* Ignore URLs */ if (array[index + 1] === ":" && array[index + 2] !== "") {
            return current;
        }
        /* Capitalize the first letter */ return current.replace(alphanumericPattern, function(match) {
            return match.toUpperCase();
        });
    }).join("");
};
// the following isEqual is a replacement for lodash's isEqual function. This doesnt have the ability to compare things
// like Buffers etc. while lodash does, but we dont need that functionality
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export var isEqual = function(first, second) {
    if (first === second) {
        return true;
    }
    if ((first === undefined || second === undefined || first === null || second === null) && (first || second)) {
        return false;
    }
    var firstType = first === null || first === void 0 ? void 0 : first.constructor.name;
    var secondType = second === null || second === void 0 ? void 0 : second.constructor.name;
    if (firstType !== secondType) {
        return false;
    }
    if (firstType === "Array") {
        if (first.length !== second.length) {
            return false;
        }
        var equal = true;
        for(var i = 0; i < first.length; i++){
            if (!isEqual(first[i], second[i])) {
                equal = false;
                break;
            }
        }
        return equal;
    }
    if (firstType === "Object") {
        var equal1 = true;
        var fKeys = Object.keys(first);
        var sKeys = Object.keys(second);
        if (fKeys.length !== sKeys.length) {
            return false;
        }
        for(var i1 = 0; i1 < fKeys.length; i1++){
            if (first[fKeys[i1]] && second[fKeys[i1]]) {
                if (first[fKeys[i1]] === second[fKeys[i1]]) {
                    continue;
                }
                if (first[fKeys[i1]] && (first[fKeys[i1]].constructor.name === "Array" || first[fKeys[i1]].constructor.name === "Object")) {
                    equal1 = isEqual(first[fKeys[i1]], second[fKeys[i1]]);
                    if (!equal1) {
                        break;
                    }
                } else if (first[fKeys[i1]] !== second[fKeys[i1]]) {
                    equal1 = false;
                    break;
                }
            } else if (first[fKeys[i1]] && !second[fKeys[i1]] || !first[fKeys[i1]] && second[fKeys[i1]]) {
                equal1 = false;
                break;
            }
        }
        return equal1;
    }
    return first === second;
};
/**
 * Copies text to clipboard
 */ export var copyToMyClipboard = function(text) {
    var windowAlert = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : undefined;
    setTimeout(function() {
        navigator.clipboard.writeText(text).then(function() {
            console.log("".concat(text, " Text copied!"));
            if (windowAlert !== undefined) {
                window.alert(windowAlert);
            }
        }, function(err) {
            console.log("Async: Could not copy text: ".concat(err));
        });
    }, 0);
};
export var generateSpeechSharePath = function(speech) {
    if (speech.slug) {
        return WebServerInternalPath.SHARE_SLUG.replace(":slug", speech.slug || "");
    }
    throw Error("Could not generate share link for speech (no slug/user/doc IDs)");
};
export var POODLI_CAL_CONNECT_QUERY_PARAM = "privateYoodliCalendar";
export function getRandomListItem(items) {
    return items[Math.floor(Math.random() * items.length)];
}
export function precedingAOrAn(word) {
    return word.match("^[aieouAIEOU].*") ? "an" : "a";
}
// get cute speech name, based on time of day
export function getCuteNameForSpeech() {
    var time = new Date().getHours();
    if (time < 4 || time > 21) {
        return "🌙 Nighttime Yoodli";
    } else if (time < 11) {
        return "☕ Breakfast Yoodli";
    } else if (time < 14) {
        return "☀️  Lunchtime Yoodli";
    } else if (time < 18) {
        return "🍵 Afternoon Yoodli";
    } else {
        return "🍷 Evening Yoodli";
    }
}
/**
 * launch poodli from webclient
 */ export var POODLI_LAUNCH_URL = "yoodli://";
export var launchPoodli = function() {
    window.location.href = POODLI_LAUNCH_URL;
};
export var launchPoodliToSpecificTab = function(tab) {
    window.location.href = "".concat(POODLI_LAUNCH_URL, "?").concat(PoodliQueryParams.TAB, "=").concat(tab);
};
export var launchPoodliToSettingsOpen = function() {
    window.location.href = "".concat(POODLI_LAUNCH_URL, "?").concat(PoodliQueryParams.SETTINGS, "=true");
};
export var launchPoodliWithCalConnectError = function(error) {
    window.location.href = "".concat(POODLI_LAUNCH_URL, "?skipCalConnectOnboarding=true&error=").concat(encodeURIComponent(error));
};
// Generates a color code based on a string
// Generates a vibrant color code based on a string
// Ensures the color is not too light or dark
export function stringToColor(string) {
    var hash = 0;
    var i;
    if (!string || string.length === 0) return "#000000";
    for(i = 0; i < string.length; i += 1){
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Use hash to generate HSL values
    var h = hash % 360;
    // Hue between 0 and 360
    var s = 85 + hash % 15;
    // Saturation between 85% and 100%
    var l = 30 + hash % 25;
    // Lightness between 45% and 60%
    // Convert HSL to RGB
    var hslToRgb = function(h, s, l) {
        s /= 100;
        l /= 100;
        var k = function(n) {
            return (n + h / 30) % 12;
        };
        var a = s * Math.min(l, 1 - l);
        var f = function(n) {
            return l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
        };
        return [
            f(0),
            f(8),
            f(4)
        ].map(function(v) {
            return Math.round(v * 255);
        });
    };
    var _hslToRgb = _sliced_to_array(hslToRgb(h, s, l), 3), r = _hslToRgb[0], g = _hslToRgb[1], b = _hslToRgb[2];
    // Ensure each RGB value is a two-digit hex
    var toHex = function(value) {
        return "0".concat(value.toString(16)).slice(-2);
    };
    return "#".concat(toHex(r)).concat(toHex(g)).concat(toHex(b));
}
/**
 * @param {*} a
 * @param {*} b
 * @returns string of the difference between a and b
 *
 */ export var getStringDiff = function(a, b) {
    var i = 0;
    var j = 0;
    var result = "";
    if (a === b) return result;
    if (a && !b) return a;
    if (b && !a) return b;
    while(j < b.length){
        if (a[i] != b[j] || i == a.length) result += b[j];
        else i++;
        j++;
    }
    return result;
};
// Returns the suffix for a number (e.g. 1st, 2nd, 3rd, 4th, etc.)
export function getNumberSuffix(num) {
    var lastDigit = num % 10;
    var lastTwoDigits = num % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return "th";
    }
    switch(lastDigit){
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}
